<template>
    <div class="bindCard_page">
        <van-loading type="spinner"
                     v-if="loading"
                     color="#F0D19E"
                     vertical>加载中...</van-loading>
        <div class="content"
             v-if="!loading">
            <div class="title_box">
                <div class="title">添加{{ bindTitle }}</div>
                <div class="titleTip">请绑定{{bindType == 1 ? '持卡人' : ''}}本人的{{ bindTitle }}</div>
            </div>
            <van-form class="form"
                      v-if="bindType == 1">
                <van-field v-model="certifyRealName"
                           label="持卡人"
                           clearable
                           name="certifyRealName"
                           placeholder="持卡人真实姓名"
                           disabled
                           class="inphone"
                           :style="boolcertifyRealName ? 'border-bottom: 1px solid transparent' : 'border-bottom: 1px solid #EFEFEF'" />
                <van-field v-model="bankNo"
                           center
                           clearable
                           type="digit"
                           name="bankNo"
                           label="卡号"
                           placeholder="持卡人本人银行卡号"
                           :style="boolKahao ? 'border-bottom: 1px solid transparent' : 'border-bottom: 1px solid #EFEFEF'" />
                <van-field v-model="bankName"
                           label="开户行"
                           clearable
                           name="bankName"
                           placeholder="银行卡开户行"
                           class="inphone"
                           :style="boolKaName ? 'border-bottom: 1px solid transparent' : 'border-bottom: 1px solid #EFEFEF'" />
                <div class="binkCardTip">
                    注：请绑定签约人银行卡号，仅支持储蓄卡提现
                </div>
            </van-form>
            <div class="buttonWrap"
                 v-if="bindType == 1">
                <van-button class="btn"
                            :class="{ 'on': subDisabled }"
                            round
                            block
                            type="info"
                            native-type="submit"
                            @click="onSubmit"
                            :disabled="subDisabled">绑卡</van-button>
            </div>
            <van-form class="form"
                      v-if="bindType == 2">
                <van-field v-model="bankNo"
                           center
                           clearable
                           name="bankNo"
                           label="支付宝账号"
                           placeholder="支付宝账号"
                           :style="boolKahao ? 'border-bottom: 1px solid transparent' : 'border-bottom: 1px solid #EFEFEF'">
                </van-field>
                <div class="empty_box">&nbsp;</div>
            </van-form>
            <div class="buttonWrap"
                 v-if="bindType == 2">
                <van-button class="btn"
                            :class="{ 'on': !bankNo }"
                            round
                            block
                            type="info"
                            native-type="submit"
                            @click="onSubmit"
                            :disabled="bankNo ? false : true">绑定</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import './index.less'
import { sessionGet, urlParse, getToken } from '@/utils/tokenUtils'
import {
    GetUserRealName,
    AddUseCashAccountNo,
    GetUserBankNewInfo,
    UpdateUserBankInfo
} from '@/service/withdraw'
export default {
    components: {},
    data() {
        return {
            loading: false,
            bindType: '', // 绑定类型
            bankName: '',
            bankNo: '',
            id: '',
            boolKahao: true,
            boolKaName: true,
            boolcertifyRealName: true,
            boolKaType: true,
            certifyRealName: ''
        }
    },
    computed: {
        // 银行卡 绑卡 按钮是否禁用
        subDisabled() {
            return !(this.certifyRealName && this.bankNo && this.bankName)
        },
        // 绑定支付宝账号的 绑定 按钮
        alipayBtnDisabled() {
            return this.bankNo ? false : true
        },
        // 绑定类型
        bindTitle() {
            if (this.bindType == 1) return '银行卡'
            if (this.bindType == 2) return '支付宝'
        }
    },
    created() {},
    mounted() {
        this.loading = true
        const urlData = urlParse(window.location.href)
        this.id = urlData.id
        this.bindType = urlData.bindType
        if (urlData.bindType == 1) {
            document.title = '银行卡绑定'
        }
        if (urlData.bindType == 2) {
            document.title = '支付宝绑定'
        }
        GetUserRealName().then((res) => {
            const { code, data } = res
            if (code == '0') {
                this.certifyRealName = data.realName || ''
            } else {
                this.$toast(res.message)
            }
            this.loading = false
        })
        if (urlData.id) {
            GetUserBankNewInfo({
                id: urlData.id
            }).then((res) => {
                const { code, data, message } = res
                if (code == '0') {
                    this.bankName = data.bankName
                    this.bankNo = data.accountNo
                } else {
                    this.$toast(res.message)
                }
                this.loading = false
            })
        }
    },
    methods: {
        onSubmit() {
            let params = {
                // ...value,
                id: this.id ? this.id : undefined,
                // certifyRealName: this.certifyRealName,
                bankNo: this.bankNo,
                bankName: this.bankName,
                accountType: this.bindType
            }
            console.log(params, 'params')
            // return
            if (!this.id) {
                AddUseCashAccountNo(params).then((res) => {
                    const { code, data, message } = res
                    if (code === '0') {
                        this.$toast.loading({
                            message: '绑定中...',
                            className: 'launch',
                            forbidClick: true,
                            // icon: 'passed',
                            // duration: 0,
                            onClose: () => {
                                this.$router.go(-1)
                            }
                        })
                    } else {
                        this.$toast(res.message)
                    }
                })
            }
            if (this.id) {
                UpdateUserBankInfo(params).then((res) => {
                    const { code, data, message } = res
                    if (code === '0') {
                        this.$toast.loading({
                            message: '绑定中...',
                            className: 'launch',
                            forbidClick: true,
                            // icon: 'passed',
                            // duration: 0,
                            onClose: () => {
                                this.$router.go(-1)
                            }
                        })
                    } else {
                        this.$toast(res.message)
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
</style>
